const PRODUCT_NAME_VS_PRODUCT_CATEGORY: { [key: string]: string } = {
  'Sprinklr Service': 'Care',
  'Sprinklr Insights': 'Research',
  'Sprinklr Social': 'Engagement',
  'Sprinklr Marketing': 'Marketing & Advertising',
  Platform: 'Platform',
};

export const getProductCategoryByProductName = (productName: string) => {
  return PRODUCT_NAME_VS_PRODUCT_CATEGORY[productName];
};
