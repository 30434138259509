/** @jsx jsx */
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { useMemo } from 'react';

import {
  HeadAdditions,
  Layout,
  OrganizationSchema,
} from '@sprinklr/shared-lib';
import { Box, jsx } from 'theme-ui';
import FeatureInfo from './components/featureInfo';

const HeaderV3 = loadable(() => import('../../templates/headerV3'));
const FooterV3 = loadable(() => import('../../templates/footerV3'));
const HeaderV2 = loadable(() => import('../../templates/headerV2'));
const Footer = loadable(
  () => import('@sprinklr/shared-lib/templates/footerTemplate'),
);
const SocialLinksTemplate = loadable(
  () => import('../../templates/socialLinksTemplate'),
);

import { reduceObjValues } from '@sprinklr/shared-lib/utils';
import { FeaturePageGradient } from '@sprinklrjs/website-components/components/gradient/featurePage/FeaturePageGradient';
import TemplateRenderer from '../../components/tempateRenderer/TemplateRenderer';
import { FeauturePageTemplateDataAttributes } from '../constants';
import { getOGImageForProductSlug } from '../utils/getOGImage';
import { getProductCategoryByProductName } from './constants';
import GradientBackground from '@sprinklrjs/website-components/components/gradientBackground';

import SEO from '@sprinklr/shared-lib/components/seo';

const FEATURE_BOX_STYLES = {
  minHeight: '100vh',
};

const FeaturePageTemplate = props => {
  const {
    path,
    data: {
      contentfulFeaturePage,
      site,
      headerV3,
      footerV3,
      allContentfulGlobalHeaderFooter,
    },
    pageContext,
  } = props;
  const {
    metadata,
    translations,
    templates,
    title,
    description,
    media,
    ctas,
    name,
    product,
    personalization,
  } = contentfulFeaturePage;

  const { header, footer, social } =
    allContentfulGlobalHeaderFooter?.edges[0]?.node ?? {};

  const metaDataProps =
    !!metadata &&
    reduceObjValues(metadata, '', {
      exclude: ['twitterImage', 'openGraphImage'],
    });

  const { slug: productSlug } = product;
  const thumbnailImageUrl = useMemo(
    () => getOGImageForProductSlug(productSlug),
    [productSlug],
  );

  const contentfulPageData = useMemo(() => {
    const productName = contentfulFeaturePage?.product?.productName;
    return {
      productCategory: productName
        ? getProductCategoryByProductName(productName)
        : null,
    };
  }, [contentfulFeaturePage]);

  const fullContext = {
    ...pageContext,
    resolvedPath: pageContext.pageSlug,
    site,
    translations,
  };

  return (
    <Layout context={fullContext} path={path}>
      <GradientBackground
        isGradientDisabled={!pageContext.isGradientBackgroundEnabled}
      >
        <OrganizationSchema path={path} />
        <HeadAdditions
          slug={pageContext.pageSlug}
          site={site}
          personalization={personalization}
        />
        {!!metaDataProps && (
          <SEO
            {...metaDataProps}
            site={site}
            domain={'https://www.sprinklr.com'}
            slug={pageContext.pageSlug}
            translations={translations?.languagesToRenderIn}
            resolvedPath={pageContext.pageSlug}
            thumbnailImageUrl={thumbnailImageUrl}
          />
        )}
        {headerV3 ? (
          <HeaderV3
            contentfulHeader={headerV3}
            isShadowEnabled
            translations={translations}
            contentfulPage={contentfulPageData}
          />
        ) : null}
        {header ? (
          <HeaderV2
            contentfulHeader={header}
            isShadowEnabled
            pageContext={pageContext}
            site={site}
            translations={translations}
            contentfulPage={contentfulPageData}
            isCtasHidden={false}
          />
        ) : null}
        <FeaturePageGradient>
          <Box
            as="main"
            sx={FEATURE_BOX_STYLES}
            id="content"
            data-element-type={
              FeauturePageTemplateDataAttributes?.dataElementType
            }
            data-element-id={FeauturePageTemplateDataAttributes?.dataElementId}
          >
            <FeatureInfo
              name={name}
              product={product}
              title={title}
              description={description}
              media={media}
              ctas={ctas}
            />
            <TemplateRenderer templates={templates} />
          </Box>
        </FeaturePageGradient>
        {social ? <SocialLinksTemplate {...social} rootSx={{ pt: 0 }} /> : null}
        {footer ? <Footer {...footer} /> : null}
        {footerV3 ? <FooterV3 contentfulFooter={footerV3} /> : null}
      </GradientBackground>
    </Layout>
  );
};

export const featurePageQuery = graphql`
  query ContentfulFeaturePageBySlug(
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
  ) {
    ...FeaturePageQuery
    ...LocalesQuery
    ...SiteMetadataQuery
    ...HeaderFooterSocialQuery
  }
`;

export default FeaturePageTemplate;
