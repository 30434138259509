import React from 'react';

import { Box, Flex, Text } from 'theme-ui';
import { CallToAction } from '@sprinklr/shared-lib';
import Media from '@sprinklr/shared-lib/components/media';
import { CTA } from '@sprinklr/shared-lib/@types/entities';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { Link } from '@sprinklr/shared-lib/components/link';
import {
  HighlightedText,
  ColorLessHighlightedText,
} from '@sprinklr/shared-lib/components/higlightedText';

import { makeFeaturePageSlug } from '../../../../../utils/makeFeaturePageSlug';

import { MediaProps } from '@sprinklr/shared-lib/components/media/types';
import { ThemeUIStyleObject } from 'theme-ui';

const TOP_CAPTION_STYLES: ThemeUIStyleObject = {
  fontSize: '12px',
  textAlign: 'center',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '0.1rem',
  textTransform: 'uppercase',
};

export const FeatureInfo = ({
  title,
  description,
  media,
  ctas,
  name,
  product,
}: {
  title: string;
  description: string;
  media: MediaProps;
  ctas?: CTA[];
  name: string;
  product: {
    productName: string;
    slug: string;
  };
}) => {
  const productSlug = makeFeaturePageSlug({ productSlug: product.slug });

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        marginBottom: ['32px', '32px', '32px'],
        marginTop: ['24px', '40px'],
        width: '100%',
        paddingX: ['16px', '32px'],
      }}
    >
      <Flex
        sx={{ flexDirection: 'column', gridGap: '10px', marginBottom: '30px' }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            gridGap: '8px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            href={productSlug}
            linkSx={{
              // color: 'sprText02',
              // textDecoration: 'none',
              '&:hover': {
                color: 'primary',
              },
            }}
          >
            <Text sx={TOP_CAPTION_STYLES}>{product.productName}</Text>
          </Link>
          <Icon
            iconName="arrow"
            iconSx={{ height: '10px', width: '10px', fill: '#434350' }}
          />
          <Text sx={TOP_CAPTION_STYLES}>{name}</Text>
        </Flex>
        <ColorLessHighlightedText
          sx={{
            maxWidth: '1000px',
            marginX: 'auto',
            textAlign: 'center',
            fontSize: ['27px', '32px', '44px'],
            lineHeight: ['40px', '40px', '54px'],
            fontWeight: 700,
            marginBottom: '0px',
            whiteSpace: 'pre-wrap',
          }}
          as="h1"
        >
          {title}
        </ColorLessHighlightedText>
        <HighlightedText
          sx={{
            maxWidth: '720px',
            textAlign: 'center',
            marginX: 'auto',
            color: 'sprText02',
            fontSize: ['15px', '16px', '17px'],
            lineHeight: ['25px', '25px', '28px'],
          }}
        >
          {description}
        </HighlightedText>
      </Flex>
      {ctas?.length ? (
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gridGap: '24px',
            marginBottom: '50px',
          }}
        >
          {ctas.map(cta => (
            <CallToAction key={cta.id} {...cta} />
          ))}
        </Flex>
      ) : null}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          filter: 'drop-shadow(6px 6px 21px rgba(0, 0, 0, 0.1))',
        }}
      >
        <Media
          {...media}
          isImgEager={true}
          source={media?.asset}
          mediaSx={{ maxWidth: '1000px' }}
        />
      </Box>
    </Flex>
  );
};
